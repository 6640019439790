<template>
    <div id="root" class="h-full flex flex-col">
        <NuxtLoadingIndicator />
        <HeaderDefault />
        <HeaderMobile />
        <main id="main" class="grow">
            <slot />
        </main>
        <FooterDefault />
    </div>
</template>
<script setup>
const { ClickEventListener } = useClickHandler();

if (import.meta.client) {
    // Global Link Click Handler
    document.addEventListener("mousedown", ClickEventListener);
}
</script>
