<template>
    <DropdownMenu>
        <HeaderMenuTrigger>
            {{ $t("Global.Filters") }}
        </HeaderMenuTrigger>
        <DropdownMenuContent>
            <HeaderRegionsSubMenu />
            <HeaderLanguageSubMenu />
            <HeaderCurrenciesSubMenu />
        </DropdownMenuContent>
    </DropdownMenu>
</template>
<script setup></script>
